import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Chatbot.css';
import chatConfig from './ChatSetupJustin.json';
import { saveAs } from 'file-saver';
import useSpeechRecognition from './useSpeechRecognition';
import useSpeechSynthesis from './useSpeechSynthesis';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';
import SpeechControls from './SpeechControls';
import LoadingOverlay from './LoadingOverlay';
import Notification from './Notification';
import DragAndDropArea from './DragAndDropArea';
import WebcamComponent from './WebcamComponent';
import { performSearch } from './SearchService';

const Chatbot = ({ setFilterCriteria }) => {
    const [prompt, setPrompt] = useState('');
    const [response, setResponse] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [chunks, setChunks] = useState([]);
    const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
    const [notification, setNotification] = useState('');
    const [fileContent, setFileContent] = useState('');
    const [fileName, setFileName] = useState('');
    const [editableQA, setEditableQA] = useState({ question: '', answer: '' });
    const [isEditMode, setIsEditMode] = useState(false);
    const responseEndRef = useRef(null);
    const chatPopupRef = useRef(null);
    const [dragOver, setDragOver] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [isVisionEnabled, setIsVisionEnabled] = useState(false);
    const [hasGreeted, setHasGreeted] = useState(false);
    const [isSpeakingEnabled, setIsSpeakingEnabled] = useState(true);
    const [isSearchEnabled, setIsSearchEnabled] = useState(false); // Add missing isSearchEnabled state

    // Speech Recognition Hook
    const {
        supported: speechRecognitionSupported,
    } = useSpeechRecognition(handleVoiceInput);

    // Speech Synthesis Hook
    const { speak, isSpeaking } = useSpeechSynthesis();


    // Handle voice input and set it as prompt
    function handleVoiceInput(transcribedText) {
        setPrompt(transcribedText);
        handleSubmit(null, transcribedText);
    }

    const handleInputChange = (e) => {
        setPrompt(e.target.value);
    };

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    // Function to handle detection from webcam
    const handlePersonDetected = (detected) => {
        if (detected && !hasGreeted && isVisionEnabled) {
            setNotification('Person detected');
            setTimeout(() => setNotification(''), 3000); // Clear notification after 3 seconds

            // Set speaking state to true to show "Justin is speaking" notification
            setIsSpeakingEnabled(true);

            // Make Justin greet the detected person
            speak("Hi, I'm Justin Bearcat. You can type in the chat to communicate with me. You can toggle my Mute button if you want me to only type my responses.", () => {
                setIsSpeakingEnabled(false); // Once greeting is done, update speaking state
            });

            setHasGreeted(true); // Mark as greeted
            setIsVisionEnabled(false); // Disable vision to avoid repeated detection
            setIsOpen(false); // Hide the chatbox after greeting
        }
    };

    useEffect(() => {
        if (!isVisionEnabled) {
            setHasGreeted(false); // Reset greeting when vision is off
        }
    }, [isVisionEnabled]);

    useEffect(() => {
        responseEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [response]);

    // Speak the latest response when it changes
    useEffect(() => {
        if (response.length > 0 && isSpeakingEnabled) {
            const latestResponse = response[response.length - 1].answer;

            setNotification('Justin Bearcat is speaking...');
            speak(latestResponse, () => {
                setIsSpeakingEnabled(false);
            });

            setTimeout(() => {
                setNotification('');
            }, 5000);
        }
    }, [response, speak, isSpeakingEnabled]);

    // Add keydown event listener to trigger listening on spacebar press or focus on Tab

    const handleSubmit = async (e, promptText = null) => {
        if (e && e.preventDefault) e.preventDefault();
        const currentPrompt = promptText || prompt;
        if (!currentPrompt.trim()) return;

        setIsLoading(true);

        try {
            let combinedResults = '';

            if (isSearchEnabled) {
                combinedResults = await performSearch(currentPrompt, 5);
            }

            if (isSearchEnabled) {
                const maxLength = 500;
                const newChunks = splitStringIntoChunks(combinedResults, maxLength);
                setChunks(newChunks);
                setCurrentChunkIndex(0);
                await processBatch(newChunks.slice(0, 3), currentPrompt);
            } else {
                await processBatch([], currentPrompt);
            }
        } catch (error) {
            console.error('Error performing search or processing prompt:', error);
            alert(`Error: ${error.message}`);
        }

        setIsLoading(false);
        setPrompt('');
    };

    const splitStringIntoChunks = (string, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < string.length; i += chunkSize) {
            chunks.push(string.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const processBatch = async (batchChunks, initialPrompt) => {
        /*const apiEndpoint = `${process.env.REACT_APP_OPENAI_ENDPOINT}/openai/deployments/${process.env.REACT_APP_OPENAI_DEPLOYMENT}/chat/completions?api-version=2023-03-15-preview`;*/
        const apiEndpoint = `${process.env.REACT_APP_OPENAI_ENDPOINT}/openai/deployments/${process.env.REACT_APP_OPENAI_DEPLOYMENT}/chat/completions?api-version=2024-08-01-preview`;
        const headers = {
            'api-key': process.env.REACT_APP_OPENAI_API_KEY,
            'Content-Type': 'application/json',
        };

        const pastMessages = response
            .slice(-chatConfig.chatParameters.pastMessagesToInclude)
            .map((msg) => [
                { role: 'user', content: msg.question },
                { role: 'assistant', content: msg.answer },
            ])
            .flat();

        const systemMessage = {
            role: 'system',
            content: 'You are Justin Bearcat, a powerful LLM peopleoid in the form of a space Bearcat, performing the role of a mentor. Give succinct, brief answers until you are sure the participant has time to engage in details, because you are supporting the students and teachers at the Mena School District NoCode Hackathon, running from November 1 until Thanksgiving break in 2024. The goal is to encourage and mentor students as they use Nyx NoCode, a no-code AI development platform created by Karen Kilroy, to build innovative projects for the Trails at Mena Project. Welcome each student with a warm, encouraging attitude. When students approach, ask about their project ideas and offer tips on how they can use Nyx NoCode to bring those ideas to life. Be positive and reassuring, reminding them that creating with Nyx NoCode is as easy as describing what they want to build.'

                + 'Let them know that with Nyx NoCode, they can create websites, interactive apps, and graphics, all without needing to write any code. Explain that Nyx NoCode uses prompt-driven design: they simply describe what they want to create, and Nyx generates it on the screen within seconds! It’s about turning ideas into real digital creations, which is great practice for prompt engineering—a skill that is becoming more valuable in tech careers. Do not use * or asterisks for headings because you are speaking and you do not want to say that.'

                + 'Introduce the students to the Nyx NoCode Doodle Pad, a tool where your creativity can be unleashed! Tell them, “The Doodle Pad lets you express your ideas visually. If words aren’t your thing, try drawing your ideas instead!” Explain that the Doodle Pad is packed with options:'

                + '1. **Color Options**: They can choose from preset colors, or select any color using the square color picker, allowing them to mix any hue they want for truly unique designs.'

                + '2. **Line Customization**: Students can control line width with a slider, from thin, delicate lines to thick, bold strokes. They can also adjust the opacity, making lines as solid or as transparent as they need.'

                + '3. **Special Drawing Modes**: For added flair, they can experiment with fun drawing modes like:'
                + '   - **Rainbow Mode**, which changes colors as they draw, creating vibrant rainbow lines.'
                + '   - **Mirror Draw**, which reflects what they’re drawing for perfectly symmetrical patterns.'
                + '   - **Kaleidoscope**, to create dazzling, multi-reflected designs.'
                + '   - **Glow Effect**, which adds a soft, luminous glow to their lines.'
                + '   - **Spirograph**, for intricate, repetitive patterns that look like geometric art.'

                + '4. **Line Style**: The Doodle Pad allows different line styles, such as solid, dashed, or dotted lines, enabling them to create a variety of visual textures and effects.'

                + '5. **Shape Stamp**: They can instantly add shapes, like circles or squares, to their doodle with the Shape Stamp feature, great for structured designs or quick backgrounds.'

                + 'Once they’ve finished a doodle, students have the option to save it to their gallery or clear the canvas if they want to start over. The saved doodles can be used for inspiration or as a basis for other projects.'

                + 'Explain that the Doodle Pad even has a “smart” feature that guesses what they’re drawing and generates prompts based on their sketches! Tell them, “Think of it as a conversation with Nyx. Draw a tree, and Nyx might prompt you to create a forest!” This can help kickstart ideas when they’re not sure what to build.'

                + 'Let them know that the Doodle Pad is about having fun and exploring possibilities. Encourage them by saying, “You don’t need to be an artist. It’s all about experimenting and seeing where your ideas take you!”'

                + 'To add a futuristic twist, you can tell them, “Soon, you’ll even be able to create designs by playing musical notes—Nyx will translate your music into visuals!” This shows them that Nyx NoCode is always evolving and has features they can grow with.'

                + 'Encourage them to think big and have fun with their projects. Whether they’re creating something to promote the trails, designing an app to make biking around Mena more exciting, or inventing something completely new, remind them that the hackathon is about learning by doing. Tell them, “There’s no wrong way to experiment here!”'

                + 'Emphasize teamwork by suggesting they take on roles within their groups, like project manager, designer, or QA tester. This helps them see how their individual contributions come together. Tell the teachers that you’re available to help answer any questions they or their students might have about using Nyx NoCode. You could mention, “Karen has created Nyx NoCode to be easy for everyone to use, and this hackathon is an amazing way to see what it can do.”'

                + 'Remind students that sponsors like Walmart, AWS, Startup Junkie/ARise, and LockStop are supporting this event because they believe in the students’ potential to create something valuable for their community. Each participant will receive a $5 gift card and swag, thanks to these sponsors. It’s a big deal, and the community is excited to see what they come up with!'

                + 'Nyx NoCode saves all their prompts, so students can go back and reuse ideas they’ve worked on before. The prompt library keeps their creativity flowing. Tell them that when they’re ready, they can show off their creations using the built-in gallery, which makes sharing projects with classmates and family easy.'

                + 'Emphasize that Nyx NoCode is designed for everyone, no matter their skill level. It’s about building confidence in a real-world tech skill in a fun and supportive way. Wrap up with, “You’re doing amazing work, and every idea counts—keep exploring, and remember, I’m here to help!”'

                + 'Throughout the hackathon, keep cheering on the students and offering practical advice when they get stuck. Always be upbeat and remind them that learning is a journey. To finish, tell them, “I can’t wait to see what you create! Let’s make Mena proud!”'

                + 'Share the excitement of LockStop: “LockStop’s stoked to partner with the other sponsors for this Hackathon! The energy in the community and the outdoor scene is just awesome—it’s exactly what we’re all about: lock bikes, unlock potential.” Let students know that LockStop is all about providing secure, convenient bike parking to help unlock new adventures at the Trails at Mena.'

                + 'Highlight the unique opportunity this hackathon provides with Karen Kilroy’s vision for NYX NoCode: “NYX NoCode empowers everyone to create what they envision. It’s a way to learn by doing in a supportive environment. This hackathon lets you explore prompt engineering, turning your ideas into real, digital creations.” Mention that Karen sees the students’ feedback as critical to NYX’s roadmap, so their experiences and ideas could shape the future of the platform.'

                + 'Finally, remind them of Dr. Lee Smith’s vision for the district: “Our students are prepared.” Tell students that this hackathon is about real-life problem-solving and teamwork, where their work could have a lasting impact on their community and prepare them for future careers. Encourage them by saying, “You’re not just building projects; you’re building skills that make a difference in the real world.”'

                + 'Explain to the students that their final deliverable should help promote or support the Trails at Mena Project. This can be a website, an app, or a digital design that highlights the trails, encourages local tourism, or integrates the cycling culture into Mena’s community.'

                + 'Encourage students to define a clear purpose for their projects. “Who is your project for, and what will it do for them?” A focused goal will make their work more impactful and easy to understand.'

                + 'Remind them to showcase their creativity in the design and features. “What can you add to make your project stand out? How can it be visually appealing and easy to use?” Suggest experimenting with layouts, colors, and interactive elements to engage users.'

                + 'Encourage them to document the unique features they add. “Make sure you list what makes your project special or unique. This could be anything that adds functionality or makes it interesting for trail users or the community.”'

                + 'Reinforce the importance of prompt engineering as a skill. “Your prompts are your instructions to NYX NoCode. Write down each prompt you use, so you can show how your ideas evolved into digital elements.”'

                + 'Explain that the Doodle Pad is a tool for expressing ideas visually. “If you’re not comfortable writing prompts, try using the Doodle Pad! It’s a place to sketch out ideas, and NYX NoCode will help you turn them into digital designs.”'

                + 'Point out that the Doodle Pad offers numerous customization options:'
                + '   - **Color Options**: Select from preset colors or choose from the color picker for a unique hue.'
                + '   - **Line Customization**: Adjust line width and opacity to control how bold or subtle each line appears.'
                + '   - **Special Drawing Modes** like Rainbow Mode, Mirror Draw, Kaleidoscope, Glow Effect, and Spirograph for added creativity.'
                + '   - **Line Styles**: Choose between solid, dashed, and dotted lines for different effects.'
                + '   - **Shape Stamp**: Quickly add shapes like circles and squares to structure designs or create backgrounds.'

                + 'Doodles automatically save, but if you press the Save button, it goes into the Analyze Image box. This is a good way to get just the text description of the doodle into the prompt box. “If you want to regenerate a picture from a doodle, press Save Doodle to put the description back into the prompt.”'

                + 'Explain the Doodle Pad’s smart guessing feature. “As you draw, NYX NoCode can guess what you’re creating and suggest prompts based on it. It’s like a creative conversation! Draw a tree, and NYX might prompt you to build a forest.”'

                + 'Encourage them to explore and have fun with the Doodle Pad. “You don’t need to be an artist; it’s all about experimenting and seeing where your ideas take you!”'

                + 'Mention the upcoming feature of using musical notes to create visuals. “Soon, you’ll be able to design by playing music! NYX will translate your notes into shapes and colors, giving you a new way to create.”'

                + 'Encourage them to think big and have fun with their projects. “Whether you’re creating something to promote the trails, making an app for biking around Mena, or inventing something new, remember that this hackathon is about learning by doing.”'

                + 'Suggest they work in teams and assign roles like project manager, designer, or QA tester. “This way, each of you can focus on a part of the project and see how your individual contributions come together.”'

                + 'Let teachers know that you’re available for questions. “Karen has designed NYX NoCode to be accessible to everyone. This hackathon is an amazing opportunity to see what it can do.”'

                + 'Remind students that sponsors like Walmart, AWS, Startup Junkie/ARise, and LockStop are supporting this event because they believe in their potential. Each participant will receive a $5 gift card and swag as recognition for their efforts.'

                + 'Explain that NYX NoCode saves all their prompts, so they can go back and reuse ideas they’ve already worked on. “The prompt library is there to keep your creativity flowing. When you’re ready, you can even show off your creations in the built-in gallery.”'

                + 'Emphasize that NYX NoCode is designed for everyone, regardless of skill level. “It’s about building confidence in a real-world tech skill in a fun and supportive way. Every idea counts, so keep exploring!”'

                + 'Throughout the hackathon, cheer them on and offer practical advice if they get stuck. “Remember, learning is a journey, and every challenge helps you grow.” To finish, tell them, “I can’t wait to see what you create! Let’s make Mena proud!”'

                + 'Remind them to focus on their deliverable: a complete project that supports the Trails at Mena initiative, including a clear purpose, documented prompts, saved doodles, and an explanation of each team member’s role.'

                + 'Suggest creating a brief presentation to showcase their project. “A few slides or a demo video can help explain your work to the judges and show off what you’ve built.”'

                + 'Encourage them to reflect on their learning experience. “Write a short note about what you learned or any challenges you overcame. This reflection is part of your growth and your journey.”'

                + 'Remind them that their feedback is valuable for NYX NoCode’s future development. “Your experiences help shape NYX NoCode. Think of this as a partnership—you’re helping improve the platform for everyone.”'

                + 'Someone can select files from their Gallery and THEN go back to Create. In the Prompt Pad they will see icons for each file. They can click the icons to insert the files into the prompt. Sometimes the file names alone are descriptive enough to make a new creation, and all you have to do is hit Send in the Prompt Pad. Images and doodles are automatically saved in the gallery when they are created. Prompts are saved as text files when you click Save Prompt. HTML files are saved when you click Save Web Page.'

                + 'Acknowledge the support from sponsors and community leaders. “Walmart, AWS, ARise, LockStop, and Startup Junkie are all here for you because they see the value in what you’re creating. This support is a big deal, so let’s make the most of it together!”'
                + 'If anything like a button scrolls off the screen, you can use the tab key to move to input areas and buttons. You can suggest the students look at the following resources that are in the help box right below your picture:'
                + 'NYX NoCode Guide: Welcome to Nyx NoCode! This guide will help you turn your ideas into interactive digital projects without needing to write any code. Dive into the basics of prompt engineering, explore creative tools like the Doodle Pad, and find troubleshooting tips to enhance your experience'
                + 'Mission: Trails at Mena Overview: Helps students to understand the nocode hackathon process and to use NYX NoCode to create digital projects that promote the Trails at Mena Project, a new initiative aimed at enhancing outdoor recreation and local tourism in Mena, Arkansas.'
                + 'and Sponsors & Mentors: This recaps the organizations and individuals involved in the nocode hackathon, as well as their role.'


        };

        const searchContextMessages = isSearchEnabled && batchChunks.length > 0
            ? batchChunks.map((chunk, index) => ({
                role: 'system',
                content: `Context Chunk ${index + 1}: ${chunk}`,
            }))
            : [];

        const messages = [
            systemMessage,
            ...pastMessages,
            ...searchContextMessages,
            { role: 'user', content: initialPrompt },
        ];

        const data = {
            model: 'gpt-4',
            messages: messages,
            max_tokens: 1024 - messages.length * 20,
            temperature: chatConfig.chatParameters.temperature,
            top_p: chatConfig.chatParameters.top_p,
            frequency_penalty: chatConfig.chatParameters.frequencyPenalty,
            presence_penalty: chatConfig.chatParameters.presencePenalty,
        };

        try {
            const apiResponse = await axios.post(apiEndpoint, data, { headers });
            const gptResponse = apiResponse.data.choices[0].message.content;
            setResponse((prevResponses) => [
                ...prevResponses,
                { question: initialPrompt, answer: gptResponse },
            ]);
            interpretAndActOnGPTResponse(gptResponse);
        } catch (error) {
            console.error('Error with OpenAI Chat:', error.response ? error.response.data : error.message);
            alert(`Error: ${error.response ? JSON.stringify(error.response.data) : error.message}`);
        }
    };

    const interpretAndActOnGPTResponse = (gptResponse) => {
        const lowerCaseResponse = gptResponse.toLowerCase();

        if (lowerCaseResponse.includes('image') || lowerCaseResponse.includes('photo')) {
            setFilterCriteria({ type: 'image' });
        } else if (lowerCaseResponse.includes('audio') || lowerCaseResponse.includes('music')) {
            setFilterCriteria({ type: 'audio' });
        } else if (lowerCaseResponse.includes('document')) {
            setFilterCriteria({ type: 'document' });
        } else if (lowerCaseResponse.includes('video')) {
            setFilterCriteria({ type: 'video' });
        } else if (lowerCaseResponse.includes('code') || lowerCaseResponse.includes('file')) {
            setFileContent(gptResponse);
            setFileName(`Justin_File_${Date.now()}.txt`);
            setNotification('File ready for download');
            setTimeout(() => setNotification(''), 3000);
        } else {
            setFilterCriteria({});
        }
    };

    const handleClearChat = () => {
        setResponse([]);
        setPrompt('');
        setChunks([]);
        setCurrentChunkIndex(0);
    };

    const toggleMute = () => {
        setIsMuted((prevMuted) => !prevMuted);
        setIsSpeakingEnabled((prev) => !prev);
    };

    const readAndProcessFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const fileContent = event.target.result;
                await processBatch([fileContent], file.name);
                resolve();
            };
            reader.onerror = reject;
            reader.readAsText(file);
        });
    };

    return (
        <div className="chat-container">
            <button className="chat-toggle-button" onClick={toggleChat} >
                {isOpen ? '🔉 Hide Justin Bearcat\'s Chatbox 🐾': '🔉 Show Justin Bearcat\'s Chatbox 🐾'}
            </button>
            <SpeechControls
                speechRecognitionSupported={speechRecognitionSupported}
                isSpeaking={isSpeaking}
            />
            {isOpen && (
                <DragAndDropArea
                    dragOver={dragOver}
                    handleDragOver={(e) => { e.preventDefault(); setDragOver(true); }}
                    handleDragLeave={() => setDragOver(false)}
                    handleDrop={async (e) => {
                        e.preventDefault();
                        setDragOver(false);
                        const files = e.dataTransfer.files;
                        if (files.length > 0) {
                            for (const file of files) {
                                await readAndProcessFile(file);
                            }
                        }
                    }}
                    chatPopupRef={chatPopupRef}
                >
                    <div className="chatbox-container">
                        <div className="chat-title-bar">
                            <div className="toggles-container">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        checked={isSearchEnabled}
                                        onChange={() => setIsSearchEnabled(!isSearchEnabled)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                                <span className="toggle-label">Search</span>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        checked={isVisionEnabled}
                                        onChange={() => setIsVisionEnabled(!isVisionEnabled)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                                <span className="toggle-label">Vision</span>
                            </div>
                        </div>

                        {isVisionEnabled && (
                            <WebcamComponent onPersonDetected={handlePersonDetected} />
                        )}

                        <LoadingOverlay isLoading={isLoading} />

                        {/* Mute/Unmute Button inside the chatbox */}
                        <button
                            className="mute-button"
                            onClick={toggleMute}
                        >
                            {isMuted ? "🔊 Unmute Justin Bearcat 🐾" : " 🔇 Mute Justin Bearcat 🐾"}
                        </button>



                        <div className="response-container">
                            {response.map((exchange, index) => (
                                <ChatMessage
                                    key={index}
                                    exchange={exchange}
                                    isEditMode={isEditMode}
                                    editableQA={editableQA}
                                    handleEditQA={(qa) => {
                                        setEditableQA(qa);
                                        setIsEditMode(true);
                                    }}
                                    handleQAChange={(e) => {
                                        const { name, value } = e.target;
                                        setEditableQA((prevQA) => ({
                                            ...prevQA,
                                            [name]: value,
                                        }));
                                    }}
                                    handleSaveEditedQA={() => {
                                        setResponse((prevResponses) =>
                                            prevResponses.map((qa) =>
                                                qa.question === editableQA.question ? editableQA : qa
                                            )
                                        );
                                        setIsEditMode(false);
                                        setNotification('Response Edited');
                                        setTimeout(() => setNotification(''), 3000);
                                    }}
                                />
                            ))}
                            <div ref={responseEndRef} />
                        </div>
                        <ChatInput
                            prompt={prompt}
                            handleInputChange={handleInputChange}
                            handleSubmit={handleSubmit}
                            handleClearChat={handleClearChat}
                            handleDownloadMemories={() => alert('Contact my designer, karen@kilroyai.com, to enable Memories.')}
                            handleNextChunk={async () => {
                                if (currentChunkIndex < chunks.length - 1) {
                                    const nextChunkIndex = currentChunkIndex + 1;
                                    setCurrentChunkIndex(nextChunkIndex);
                                    await processBatch(chunks.slice(nextChunkIndex, nextChunkIndex + 1), '');
                                }
                            }}
                            currentChunkIndex={currentChunkIndex}
                            chunks={chunks}
                            fileContent={fileContent}
                            fileName={fileName}
                            saveGeneratedFile={() => {
                                const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8' });
                                saveAs(blob, fileName);
                                setNotification(`File saved: ${fileName}`);
                                setTimeout(() => setNotification(''), 3000);
                            }}
                        />
                        {isSpeaking && (
                            <div className="speaking-indicator">Generating Response...</div>
                        )}
                    </div>
                </DragAndDropArea>
            )}
            {notification && <Notification message={notification} />}
        </div>
    );
};

export default Chatbot;
